import ChangePasswordDialog from '@/components/ChangePasswordDialog'
import { Outlet } from 'react-router-dom'
import { ReactNode } from 'react'

interface MainLayoutProps {
    children?: ReactNode
}

const MainLayout = ({ children }: MainLayoutProps) => {
    return (
        <>
            <div
                className="relative z-10 flex flex-col flex-1 h-screen overflow-hidden bg-gradient-to-br from-orange-100 to-violet-100"
                vaul-drawer-wrapper="true"
            >
                {children || <Outlet />}
            </div>
            <ChangePasswordDialog />
        </>
    )
}

export default MainLayout
